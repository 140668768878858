import React from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
  Avatar,
  IFan,
  Note,
} from '@ui/components';
import { twMerge } from 'tailwind-merge';
import LeaderboardScoreInfo from './leaderboard-score-info';

interface LeaderoardAccordionItemProp {
  fan: IFan;
  fanId?: string | null;
  isBadgeLeaderboard?: boolean;
  onFanOpen?(id: string, rank: number, totalFans?: number): void;
}

const LeaderoardAccordionItem: React.FC<LeaderoardAccordionItemProp> = ({
  fan,
  fanId,
  isBadgeLeaderboard,
  onFanOpen,
}) => {
  function onClick(id: string, rank: number, totalFans?: number) {
    if (onFanOpen) {
      onFanOpen(id, rank, totalFans);
    }
  }
  return (
    <div>
      <Accordion
        type="single"
        collapsible
        className={twMerge(
          'bg-bgGrey  rounded-2xl p-4 transition ',
          isBadgeLeaderboard ? 'md:w-full' : '',
          fan.id == fanId && 'bg-[#FFD197]',
          onFanOpen ? 'hover:bg-inputActive active:bg-border cursor-pointer' : '',
        )}
        key={fan.id}>
        <AccordionItem value={fan.id} className="border-none">
          <AccordionTrigger className="hover:no-underline p-1">
            <div className="flex items-center justify-between w-full">
              <div
                className="flex items-center space-x-2"
                onClick={() => onClick(fan?.id, fan?.rank, fan.rankOutOf)}>
                <Avatar
                  size="s"
                  src={fan?.avatarUrl || fan?.avatar_url || fan?.avatar}
                  isSubscribed={fan?.is_subscribed_to_creator}
                />
                <Note type="m" className="text-subtitle font-bold truncate">
                  {fan.name}
                </Note>
              </div>
              <div className="text-subtitle items-center rounded-full bg-white px-3 py-1 text-base">
                {fan.rank}
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent className="text-xs px-2 py-2 w-full">
            <div className="w-full flex space-x-2">
              <LeaderboardScoreInfo
                label="Badges"
                score={
                  (fan?.score && !isNaN(Number(fan?.score)) && Number(fan?.score).toFixed(4)) || '-'
                }
                bgColor={fan.id == fanId ? 'bg-[rgba(255,255,255,0.3)]' : 'bg-[rgba(0,0,0,0.05)]'}
              />
              <LeaderboardScoreInfo
                label="Engagement"
                score={(fan?.engagement_score && Number(fan?.engagement_score).toFixed(4)) ?? '-'}
                bgColor={fan.id == fanId ? 'bg-[rgba(255,255,255,0.3)]' : 'bg-[rgba(0,0,0,0.05)]'}
              />
              <LeaderboardScoreInfo
                label="Overall Score"
                score={(fan?.overall_score && Number(fan?.overall_score).toFixed(4)) ?? '-'}
                bgColor={fan.id == fanId ? 'bg-[rgba(255,255,255,0.3)]' : 'bg-[rgba(0,0,0,0.05)]'}
              />
            </div>
          </AccordionContent>
        </AccordionItem>
      </Accordion>
    </div>
  );
};
const LeaderoardScoreItem: React.FC<LeaderoardAccordionItemProp> = ({
  fan,
  fanId,
  isBadgeLeaderboard,
  onFanOpen,
}) => {
  function onClick(id: string, rank: number, totalFans?: number) {
    if (onFanOpen) {
      onFanOpen(id, rank, totalFans);
    }
  }
  return (
    <div
      id={`fan-${fan.id}`}
      className={twMerge(
        'bg-bgGrey rounded-full p-4 transition',
        isBadgeLeaderboard ? 'md:w-full' : '',
        fan.id == fanId && 'bg-[#FFD197]',
        onFanOpen ? 'hover:bg-inputActive active:bg-border cursor-pointer' : '',
      )}
      onClick={() => onClick(fan?.id, fan?.rank, fan.rankOutOf)}
      key={fan.rank}>
      <div className="flex items-center space-x-4">
        <div className="flex-shrink-0">
          <Avatar size="s" src={fan.avatar} isSubscribed={fan?.is_subscribed_to_creator} />
        </div>
        <div className="min-w-0 flex-1">
          <Note type="m" className="text-subtitle truncate">
            {fan.name}
          </Note>
          <Note type="xs" className="block truncate">
            {fan?.score && !isNaN(Number(fan?.score)) && Number(fan.score)?.toFixed(4)}
          </Note>
        </div>
        <div className="text-subtitle items-center rounded-full bg-white px-3 py-1 text-base">
          {fan.rank}
        </div>
      </div>
    </div>
  );
};

export { LeaderoardAccordionItem, LeaderoardScoreItem };
