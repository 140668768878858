import { twMerge } from 'tailwind-merge';
import './loading-skeleton.style.css';

interface ILoadingSkeleton {
  className?: string;
  style?: React.CSSProperties;
  theme?: 'light' | 'dark';
}

export function LoadingSkeleton({
  className,
  children,
  theme = 'light',
  style,
}: React.PropsWithChildren<ILoadingSkeleton>) {
  const animation = theme === 'light' ? 'loading-animation' : 'loading-animation-dark';
  return (
    <div className={twMerge(animation, 'rounded-xl', className)} style={style}>
      {children}
    </div>
  );
}
