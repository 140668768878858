'use client';
import { Button, FormPage, Icon, TabButton } from '@ui/components/atoms';

import { BadgeClaimImage } from './components/badge-claim-image';
import {
  BadgeClaimSubmit,
  IBadgeClaimSubmit,
} from './components/badge-claim-info/badge-claim-info-panel/badge-claim-submit';
import { BadgeClaimNamePanel } from './components/badge-claim-info/badge-claim-name-panel';
import { BadgeClaimCreators } from './components/badge-claim-info/badge-claim-creators';
import {
  IBadgeClaimBadge,
  IBadgeClaimUser,
  IBadgeClaimCreators,
  IBadgeClaimType,
} from './badge-claim.typings';
import { badgeClaimClassName } from './badge-claim.style';
import { BadgeClaimInfoLayout } from './components/badge-claim-info/badge-claim-info-layout';
import { BadgeClaimInfoPanel } from './components/badge-claim-info/badge-claim-info-panel/badge-claim-info-panel';
import { useMemo, useState } from 'react';
import { twMerge } from 'tailwind-merge';
import { BadgeLeaderboard, IBadgeLeaderboardProps } from './components/badge-leaderboard';
import { INotification } from '../message';
import { BadgeType } from '@ui/constants';

type IBadgeTab = 'Info' | 'Leaderboard';

export interface IBadgeClaim extends IBadgeClaimSubmit {
  badge: IBadgeClaimBadge;
  creators: IBadgeClaimCreators;
  user: IBadgeClaimUser;
  className?: string;
  claimType?: IBadgeClaimType;
  leaderboard?: Omit<IBadgeLeaderboardProps, 'onBack' | 'avatar'>;
  currentTab?: string;
  onBack?(): void;
  gotoForum?(): void;
  openChallenge?(): void;
  isClaimExpired?: boolean;
  creatorHasCommunity?: boolean;
  hasAchieved?: boolean;
  shouldBeGrey?: boolean;
  newPostNotifications?: any;
  badgeInChallenge?: boolean;
  badgeOwned?: boolean;
}

export function BadgeClaim({
  badge,
  creators,
  user,
  isClaimExpired,
  leaderboard,
  badgeState = 'default',
  claimType: badgeClaimType = 'default',
  isLoading,
  currentTab,
  shouldBeGrey,
  onBack,
  onClick,
  gotoForum,
  openChallenge,
  badgeOwned,
  hasAchieved,
  creatorHasCommunity,
  newPostNotifications,
  badgeInChallenge,
}: IBadgeClaim) {
  const className = badgeClaimClassName[badgeClaimType];
  const [tab, setTab] = useState<IBadgeTab>('Info');

  function onBadgeLeaderboard() {
    if (!leaderboard) {
      return;
    }
    setTab('Leaderboard');
  }

  function onInfo() {
    setTab('Info');
  }

  const badgeNumber = useMemo(() => {
    if (
      !badge?.isAvailable &&
      badge.type != BadgeType.Prize &&
      badge?.type != BadgeType.Community
    ) {
      return badge?.badgeNumber;
    } else {
      return badge?.badgeNumber;
    }
  }, [badge, currentTab]);

  return (
    <FormPage className={className.container.main}>
      <BadgeClaimImage
        badgeState={badgeState}
        name={badge.name}
        caption={badge.caption}
        shouldBeGrey={shouldBeGrey}
        image={badge.image}
        isLoggedIn={user?.isLoggedIn}
        isSubscribed={user?.isSubscribed}
        color={badge.color}
        type={badge.type}
        avatar={user.avatar}
        showAvatar={user.showAvatar}
        badgeNumber={badgeNumber}
        badgeAmount={badge.amount}
        claimType={badgeClaimType}
        badgeType={badge?.type}
        perks={badge.perks}
        onBack={onBack}
        gotoForum={gotoForum}
        creatorHasCommunity={creatorHasCommunity}
        onBadgeLeaderboard={user.showAvatar ? onBadgeLeaderboard : undefined}
        newPostNotifications={newPostNotifications}
        badgeInChallenge={badgeInChallenge}
        openChallenge={openChallenge}
        hasAchieved={hasAchieved}
      />
      <BadgeClaimInfoLayout
        claimType={badgeClaimType}
        color={badge.color}
        user={user}
        onBack={onBack}>
        <div
          className={twMerge(
            'flex flex-col rounded-t-lg bg-white lg:flex-1',
            badgeClaimClassName[badgeClaimType].badgeDetails.infoContainer,
          )}>
          <div
            className={twMerge(
              'hidden pl-8 pr-10 lg:block',
              badgeClaimClassName[badgeClaimType].badgeDetails.tabPanel,
              user.showAvatar ? '' : 'lg:hidden',
            )}>
            <div className="flex gap-3 pt-10">
              <TabButton onClick={onInfo} type={tab === 'Info' ? 'active' : 'default'}>
                Info
              </TabButton>
              <TabButton
                onClick={onBadgeLeaderboard}
                type={tab === 'Leaderboard' ? 'active' : 'default'}>
                Ranking
              </TabButton>
              {creatorHasCommunity && (
                <div className="relative w-fit max-w-[250px]">
                  {newPostNotifications && newPostNotifications?.unread_count > 0 && (
                    <div className="w-6 h-6 p-2 rounded-full bg-primary text-white absolute top-0 right-0 flex items-center justify-center text-xs">
                      {newPostNotifications?.unread_count}
                    </div>
                  )}
                  <Button
                    type="primary"
                    className="w-full text-sm lg:text-lg bg-black hover:bg-black/95"
                    onClick={gotoForum}>
                    <Icon type="community" className="mr-3" /> Community
                  </Button>
                </div>
              )}
            </div>
          </div>
          {tab === 'Leaderboard' && !!leaderboard ? (
            <BadgeLeaderboard {...leaderboard} onBack={onInfo} avatar={user.avatar} />
          ) : (
            <>
              <BadgeClaimNamePanel
                claimType={badgeClaimType}
                name={badge.name}
                caption={badge.caption}
              />
              <BadgeClaimCreators {...creators} claimType={badgeClaimType}>
                <BadgeClaimInfoPanel
                  creatorHasCommunity={creatorHasCommunity}
                  badgeOwned={badgeOwned}
                  isClaimExpired={isClaimExpired}
                  creators={creators}
                  badge={{ ...badge, badgeNumber }}
                  badgeState={badgeState}
                  claimType={badgeClaimType}
                  gotoForum={gotoForum}
                  currentTab={currentTab}
                  badgeType={badge?.type}>
                  <BadgeClaimSubmit
                    hasAchieved={hasAchieved}
                    sponsors={badge?.sponsors}
                    badgeType={badge?.type}
                    publicLink={badge?.publicLink}
                    openChallenge={openChallenge}
                    isClaimExpired={isClaimExpired}
                    badgeState={badgeState}
                    isLoading={isLoading}
                    onClick={onClick}
                  />
                </BadgeClaimInfoPanel>
              </BadgeClaimCreators>
            </>
          )}
        </div>
      </BadgeClaimInfoLayout>
    </FormPage>
  );
}
