import { useEffect, useState } from 'react';
import posthog from 'posthog-js';

export function usePostHogFeatureFlag(flagName: string): boolean | null {
  const [flagEnabled, setFlagEnabled] = useState<boolean | null>(null);

  useEffect(() => {
    let isMounted = true;

    const checkFeatureFlag = () => {
      if (!isMounted) return;

      const isEnabled = posthog.isFeatureEnabled(flagName);
      if (typeof isEnabled === 'boolean') {
        setFlagEnabled(isEnabled);
      } else {
        setFlagEnabled(false);
      }
    };

    checkFeatureFlag();

    posthog.featureFlags.addFeatureFlagsHandler(checkFeatureFlag);

    return () => {
      isMounted = false;
      posthog.featureFlags.removeFeatureFlagsHandler(checkFeatureFlag);
    };
  }, [flagName]);

  return flagEnabled;
}
