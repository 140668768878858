import { twMerge } from 'tailwind-merge';

interface ISidebarLayout {
  wrapperClassName?: string;
  className?: string;
}

export function SidebarLayout({
  children,
  wrapperClassName,
  className,
}: React.PropsWithChildren<ISidebarLayout>) {
  return (
    <div
      className={twMerge(
        'w-[280px] fixed left-0 top-0 flex h-screen flex-col justify-between',
        className,
        wrapperClassName,
      )}>
      {children}
    </div>
  );
}
