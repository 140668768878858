'use client';
import { Input } from '@ui/components/moleculas';
import {
  IMessagePayload,
  useMessageContext,
  useConversationContext,
  useToast,
} from '@ui/components';
import { useEffect, useState } from 'react';
import { useFiles } from '@ui/hooks';
import { MAX_FILES } from '@ui/hooks/file/constants';
import { sendGAEvent } from '@next/third-parties/google';
import dynamic from 'next/dynamic';
const RichTextEditor = dynamic(
  () => import('@ui/components/ui/rte-editor/rte-editor').then((mod) => mod.RichTextEditor),
  { ssr: false },
);

const initialState: IMessagePayload = {
  send_email: false,
  files: {
    images: [],
  },
  name: '',
  description: '',
};

export const PostForm = () => {
  const toast = useToast();
  const messageState = useMessageContext('creator');
  const conversationState = useConversationContext();
  const [message, setMessage] = useState<IMessagePayload>(initialState);
  const { files, onSelectFiles, deleteFile, onDiscardFiles } = useFiles();

  useEffect(() => {
    setMessage((state) => ({ ...state, files: { images: files } }));
  }, [files]);

  const onSubmit = async (
    description: string,
    mentions: string[],
    clearEditorContent: () => void,
  ) => {
    try {
      const res = await messageState.onCreateMessage({
        ...message,
        description,
        creator_id: conversationState.creator_id,
        thread_id: messageState.query.thread_id,
        user_id: conversationState.creator_id,
        mentions,
      });
      if (res?.data?.message?.id) {
        setMessage(initialState);
        onDiscardFiles();
        clearEditorContent();
        // Send GA Event for Successful Post
        sendGAEvent('event', 'CreatePostSuccessful', {
          event_label: 'Creator Post success',
          creatorId: conversationState.creator_id,
          postId: res?.data.message?.id,
        });
        // create post thread
        await messageState.onCreateMessageThread({
          message_id: res?.data?.message?.id,
          conversation_id: res?.data?.message_thread?.conversation_id,
          queryParams: messageState.query,
        });
      } else {
        toast({ title: 'Error creating post, please try again' }, { type: 'error' });
      }
    } catch (e) {
      toast({ title: 'Error creating post, please try again' }, { type: 'error' });

      // Send GA Event for failed Post
      sendGAEvent('event', 'CreatePostFailed', {
        creatorId: conversationState.creator_id,
        event_label: 'Creator Post Failed',
      });
    }
  };

  const onCancelEditor = () => {
    setMessage(initialState);
    onDiscardFiles();
  };

  return (
    <div className="flex flex-col gap-1">
      <Input
        value={message.name}
        type="text"
        isHideError={true}
        placeholder="Title"
        onChange={(event: any) => setMessage({ ...message, name: event.target.value })}
      />
      <RichTextEditor
        deleteFile={deleteFile}
        onSelectFiles={onSelectFiles}
        isSubmitButtonDisabled={!Boolean(message.name)}
        authorAvatarURL={conversationState.userAvatarURL}
        onChange={onSubmit}
        value={message.description || ''}
        userType="creator"
        currentUser={messageState.isCreator ? 'creator' : 'fan'}
        files={files}
        isLoading={messageState.isCreating || messageState.isPostingImage}
        exceededFileLimit={files.length >= MAX_FILES}
        placeholder="Compose a post..."
        editorLabel="Share with your audience"
        buttonLabel="Post"
        maxFiles={MAX_FILES}
        showCancelButton={false}
        onCancel={onCancelEditor}
        enableSuggestions
        initialMention={undefined}
      />
    </div>
  );
};
