'use client';
import { ReactNode, useMemo, useState } from 'react';
import { Loader } from 'lucide-react';
import {
  Button,
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  IGroup,
  IOption,
  SearchableMultiSelect,
  TConversationParticipantSource,
  useConversationContext,
} from '@ui/components';

export interface IProps {
  onAddMembers: (members: TConversationParticipantSource) => void;
  children: ReactNode;
}
export const CohortSelectionModal = ({ children, onAddMembers }: IProps) => {
  const state = useConversationContext();
  const [selections, setSelections] = useState<IOption<IGroup>[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [isFetching, setIsFetching] = useState(false);

  const isDisabled = useMemo(() => {
    return state.isGroupLoading || isFetching;
  }, [state.isGroupLoading, isFetching]);

  const options = useMemo(() => state.groupData as IOption[], [state.groupData]);

  const onAdd = async () => {
    onAddMembers({
      type: 'cohort',
      cohort_id: selections[0].value,
      count: selections[0].meta?.associated_fans || 0,
      label: selections[0].label,
    });
    setIsOpen(false);
  };

  return (
    <Dialog open={isOpen} onOpenChange={setIsOpen}>
      <DialogTrigger asChild>
        <span>{children}</span>
      </DialogTrigger>
      <DialogContent
        className="bg-white p-6 sm:max-w-lg"
        style={{ cursor: state.isGroupLoading ? 'progress' : 'default' }}>
        <DialogHeader>
          <DialogTitle className="text-2xl">Select Cohorts</DialogTitle>
        </DialogHeader>

        <SearchableMultiSelect
          isFormControlled={false}
          placeholder="Search cohort..."
          className="w-full max-w-[calc(32rem-50px)]"
          options={options}
          setValue={setSelections}
          maxSelection={1}
          useBadgeImage={false}
        />

        <DialogFooter className="justify-end">
          <Button
            type="primary"
            size="small"
            disabled={isDisabled || selections.length === 0}
            onClick={onAdd}>
            {isFetching && <Loader className="mr-1 animate-spin" />}{' '}
            {isFetching ? 'Please wait...' : 'Add cohorts'}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};
