import { Avatar, Text } from '@ui/components';
import { twMerge } from 'tailwind-merge';
import { IFan } from '../leaderboard.typings';

interface ITopFanAvatar {
  place: 1 | 2 | 3;
  fan: IFan;
  onFanOpen?(id: string, rank: number, totalFans?: number): void;
}

const colorMap = {
  1: '#FF600D',
  2: '#07CA4B',
  3: '#0D99FF',
};

export function TopFanAvatar({ place, fan, onFanOpen }: ITopFanAvatar) {
  const color = colorMap[place];

  function onFanClick() {
    if (!onFanOpen) {
      return;
    }
    onFanOpen(fan.id, fan.rank, fan?.rankOutOf);
  }

  return (
    <div
      onClick={onFanClick}
      className={twMerge(
        'hover:bg-input active:bg-inputActive flex cursor-pointer flex-col items-center gap-2 rounded-sm p-2 transition-all',
        place === 1 ? 'w-28' : 'w-24',
      )}>
      <div
        className={'relative rounded-full border-2 border-blue-300'}
        style={{ borderColor: color }}>
        <Avatar
          size={place === 1 ? 'l' : 'ml'}
          wrapperClassName="p-0"
          className="border-2"
          src={fan.avatar}
        />
        <div className="absolute -bottom-2 left-0 right-0 flex justify-center">
          <div
            className="flex aspect-square h-5 items-center justify-center rounded-full bg-blue-300"
            style={{ backgroundColor: color }}>
            <Text type="m" className="flex text-white">
              {place}
            </Text>
          </div>
        </div>
      </div>
      <Text
        type="m"
        className={twMerge(
          'font-nunito min-h-[20px] truncate text-center font-extrabold',
          place === 1 ? 'w-24' : 'w-20',
        )}>
        {fan.name}
      </Text>
    </div>
  );
}
