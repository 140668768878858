import { twMerge } from 'tailwind-merge';
import defaultAvatar from '@ui/assets/images/DefaultAvatar.png';
import { LoadingSkeleton } from './loading-skeleton';
import { ContextUserType } from '@ui/constants/context';
import { Icon } from './icon';
import Lightningbolt from '@ui/assets/images/Lightningbolt.png';

const GRADIENT_STYLE: Record<string, string> = {
  fan: 'bg-gradient-to-r from-[#8F00FF] to-[#8F00FF]',
  creator: 'bg-gradient-to-r from-[#8F00FF] to-[#FFA500]',
};

const AVATAR_SIZE = {
  xxl: 'w-44 h-44',
  xl: 'w-32 h-32',
  l: 'h-24 w-24',
  ml: 'h-20 w-20',
  m: 'h-16 w-16',
  sm: 'h-12 w-12',
  s: 'h-8 w-8',
  xs: 'h-6 w-6',
};

const SUBSCRIPTION_ICON_SIZE = {
  xxl: 'h-10 w-10 bottom-[-20px]',
  xl: 'h-8 w-8 bottom-[-18px]',
  l: 'h-7 w-7 bottom-[-16px]',
  ml: 'h-6 w-6 bottom-[-14px]',
  m: 'h-5 w-5 bottom-[-12px]',
  sm: 'h-4 w-4 bottom-[-10px]',
  s: 'h-3 w-3 bottom-[-8px]',
  xs: 'h-2.5 w-2.5 bottom-[-6px]',
};

const GLOW_BORDER_SIZE = {
  xxl: 'border-[6px]',
  xl: 'border-[5px]',
  l: 'border-[4px]',
  ml: 'border-[3px]',
  m: 'border-[3px]',
  sm: 'border-[2px]',
  s: 'border-[2px]',
  xs: 'border-[1px]',
};

const BORDER_SIZE = {
  xxl: 'border-4',
  xl: 'border-4',
  l: 'border-4',
  ml: 'border-2',
  m: 'border-2',
  sm: 'border-2',
  s: 'border-2',
  xs: 'border-2',
};

const GRADIENT_SIZE = {
  xxl: 'p-1',
  xl: 'p-1',
  l: 'p-1',
  ml: 'p-1',
  m: 'p-1',
  sm: 'p-0.5',
  s: 'p-0.5',
  xs: 'p-0.5',
};

export type IAvatarSize = keyof typeof AVATAR_SIZE;

export interface IAvatar {
  src?: string;
  size?: IAvatarSize;
  type?: ContextUserType;
  className?: string;
  wrapperClassName?: string;
  withGradient?: boolean;
  isLoading?: boolean;
  isSubscribed?: boolean;
}

export function Avatar({
  size,
  src,
  className,
  isLoading,
  withGradient,
  wrapperClassName,
  type,
  isSubscribed,
}: IAvatar) {
  const key = size || 'm';
  const sizeStyle = AVATAR_SIZE[key];
  const borderStyle = BORDER_SIZE[key];
  const gradientSize = GRADIENT_SIZE[key];
  const iconSize = SUBSCRIPTION_ICON_SIZE[key];
  const glowBorderSize = GLOW_BORDER_SIZE[key];

  const image = src ? src : defaultAvatar.src;
  const gradientStyle = isSubscribed
    ? 'bg-gradient-to-br from-[#FB9151] to-[#FFC4A3]' // Second orange is lighter
    : GRADIENT_STYLE[type || ''];

  const glowStyle = isSubscribed
    ? twMerge(
        'border-transparent bg-[linear-gradient(45deg, white 15%, #FB9151 50%, #FB9151 100%)]',
        glowBorderSize,
      )
    : '';

  return (
    <div
      className={twMerge(
        'aspect-square h-max w-max rounded-full bg-transparent bg-gradient-to-br from-[#8F00FF] to-[#FFA500]',
        gradientSize,
        withGradient && !isLoading ? '' : 'from-transparent to-transparent',
        wrapperClassName,
        gradientStyle,
        glowStyle,
      )}>
      {isLoading ? (
        <LoadingSkeleton
          className={twMerge(
            'aspect-square rounded-full border-white',
            sizeStyle,
            borderStyle,
            className,
          )}
        />
      ) : (
        <div className="relative">
          <img
            className={twMerge(
              'aspect-square rounded-full border-white bg-white object-cover',
              sizeStyle,
              borderStyle,
              className,
            )}
            src={image}
          />

          {isSubscribed && (
            <img
              src={Lightningbolt.src}
              className={twMerge(
                'absolute left-1/2 transform -translate-x-1/2',
                iconSize, // Dynamic size & position
              )}
            />
          )}
        </div>
      )}
    </div>
  );
}
