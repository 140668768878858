'use client';
import { usePostHogFeatureFlag } from '@ui/hooks';
import { BannerV2 } from './banner-v2';
import { BannerV1 } from './banner-v1';

export const BannerTypeSizeMap = {
  fan: 'rounded-t-xl',
  creator: 'h-20 rounded-t-md',
  preview: 'rounded-t-xl max-h-[160px]',
  content: 'h-40 rounded-t-md',
};

export type IBannerType = keyof typeof BannerTypeSizeMap;

interface IBanner {
  image?: string;
  type: IBannerType;
  className?: string;
  isLoading?: boolean;
  showSpinner?: boolean;
  showOverlay?: boolean;
  onImageLoad?: () => void;
}

export function Banner(props: IBanner) {
  const isBannerOverlayEnabled = usePostHogFeatureFlag('banner-overlay');

  if (isBannerOverlayEnabled) {
    return <BannerV2 {...props} />;
  }
  return <BannerV1 {...props} />;
}
