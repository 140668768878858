'use client';
import { twMerge } from 'tailwind-merge';
import DefaultBannerImageV2 from '@ui/assets/images/DefaultBanner-v2.png';
import { LoadingSkeleton } from './loading-skeleton';
import { IBannerType, Spinner } from '@ui/components';
import BannerOverlayImage from '@ui/assets/images/banner_overlay.png';

const BannerTypeSizeMap = {
  fan: 'rounded-t-xl',
  creator: 'h-20 rounded-t-md',
  preview: 'rounded-t-xl max-h-[160px]',
  content: 'h-40 rounded-t-md',
};

interface IBanner {
  image?: string;
  type: IBannerType;
  className?: string;
  isLoading?: boolean;
  showSpinner?: boolean;
  showOverlay?: boolean;
  onImageLoad?: () => void;
}

export function BannerV2({
  image,
  type,
  isLoading,
  className,
  showSpinner = false,
  showOverlay = false,
  onImageLoad,
}: IBanner) {
  const typeStyles = BannerTypeSizeMap[type];
  const bgImage = image || DefaultBannerImageV2.src;

  return isLoading ? (
    <LoadingSkeleton
      className={twMerge('h-auto max-h-[286px] min-h-[100px] w-full', typeStyles, className)}
    />
  ) : (
    <div className="relative">
      {showOverlay && (
        <img
          className={twMerge(
            'h-auto max-h-[286px] min-h-[100px] w-full absolute',
            typeStyles,
            className,
          )}
          src={BannerOverlayImage.src}
          style={{ zIndex: 9999 }}
        />
      )}

      {showSpinner && (
        <Spinner className="absolute z-10 animate-spin top-44 left-32" fill={'#FF600D'} />
      )}
      <img
        className={twMerge('h-auto max-h-[286px] min-h-[100px] w-full', typeStyles, className)}
        src={bgImage}
        onLoad={onImageLoad}
      />
    </div>
  );
}
